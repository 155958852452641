$(document).ready(function(){
});

const body = document.body
/* isMobile */
const ismobile = document.querySelector('.ismobile')
let isMobile = null;
let windowW = window.innerWidth;

/**
 *
 * @param {Function} callback
 * @param {number} delay
 * @returns {Function}
 */
const debounce = function (callback, delay){
    let timer;
    return function(){
        let args = arguments;
        let context = this;
        clearTimeout(timer);
        timer = setTimeout(function(){
            callback.apply(context, args);
        }, delay)
    }
}
/**
 *
 * @param {HTMLElement} el
 */
const isHidden = function (el){
    return (el.offsetParent === null)
}

if (ismobile !== null) {
    isMobile = !isHidden(ismobile)
    window.addEventListener('resize', debounce(function () {
        isMobile = !isHidden(ismobile);
    }, 500))
}

