/*
 * Replace all SVG images with inline SVG
 */
$(function() {
    $('img.svg').filter(function() {
        return this.src.match(/.*\.svg$/);
    }).each(function(){
        var $img = $(this);
        var imgID = $img.attr('id');
        var imgClass = $img.attr('class');
        var imgURL = $img.attr('src');
        $.get(imgURL, function(data) {
            // Get the SVG tag, ignore the rest
            var $svg = $(data).find('svg');
            var $path = $(data).find('path');
            // Add replaced image's ID to the new SVG
            if (typeof imgID !== 'undefined') {
                $svg = $svg.attr('id', imgID);
            }
            // Add replaced image's classes to the new SVG
            if (typeof imgClass !== 'undefined') {
                $svg = $svg.attr('class', imgClass + ' replaced-svg');
            }
            // Remove any invalid XML tags as per http://validator.w3.org
            $svg = $svg.removeAttr('xmlns:a');
            // Replace fill attribute to currentColor for manipulate in css color attribute
            $svg.attr('fill','currentColor');
            //$path.attr('fill','currentColor');
            // Replace image with new SVG
            $img.replaceWith($svg);
        }, 'xml');
    });
});