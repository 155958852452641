$(document).ready(function () {
    const $html = $('html');
    const $body = $('body');
    const $button = $('.nav--trigger');
    $button.on('click', function () {
        if ($body.hasClass('sub-menu-open')) {
            $('.sub-menu-open').removeClass('sub-menu-open');
        } else {
            if ($body.hasClass('menu-open')) {
                $body.removeClass('menu-open');
            } else {
                $body.addClass('menu-open');
                $('.sub-menu-open').removeClass('sub-menu-open');
            }
        }
    });
    $(document).on('click', function (e) {
        let isTouch = false;
        if ($html.hasClass('touchevents')) isTouch = true;
        let isNavBurger = false;
        if ($button.is(':visible')) isNavBurger = true;
        if ($(e.target).hasClass('menu-open')) {
            $body.removeClass('menu-open');
            $('.sub-menu-open').removeClass('sub-menu-open');
        }
        if (isTouch && !isNavBurger) {
            if (e.target.tagName !== 'A' && $('li.open').length) {
                $menuItemsHasChildren.removeClass('open');
            }
        }
    });
    const $menuItemsHasChildren = $('li.menu-item-has-children');
    const $menuItemsHasChildrenLink = $('> a', $menuItemsHasChildren);
    $menuItemsHasChildrenLink.append('<span class="nav--item--arrow"><i class="fas fa-chevron-down"></i></span>');
    $menuItemsHasChildrenLink.on('click', function (e) {
        let isTouch = false;
        if ($html.hasClass('touchevents')) isTouch = true;
        let isNavBurger = false;
        if ($button.is(':visible')) isNavBurger = true;
        const $li = $(this).parent();
        const $ul = $li.parent();
        if (isNavBurger) {
            if (!$li.hasClass('sub-menu-open')) {
                $body.addClass('sub-menu-open');
                $ul.addClass('sub-menu-open');
                $li.addClass('sub-menu-open');
                e.preventDefault();
            }
        } else if (isTouch) {
            if (!$li.hasClass('open')) {
                $menuItemsHasChildren.removeClass('open');
                $li.addClass('open');
                e.preventDefault();
            }
        }
    });
});